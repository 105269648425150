@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';

.section__headline{

  @include breakpoint('break-1'){
    padding-top:8rem;
  }

  h2{display: inline;}

  .down-arrow{
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    padding-left: 0.5rem;    

    @include breakpoint('break-1'){
      height: 5vw;
      padding-left: 1vw;    
    }

    svg{height: 100%; width: auto;}
  }

}



