@import './_breakpoints.scss';

.col-1{
  flex: 0 0 100%;
  @include breakpoint('break-1'){ flex: 0 0 33.3333%; }
}

.col-2{
  flex: 0 0 100%;
  @include breakpoint('break-1'){ flex: 0 0 66.6666%; }
}

.col-3{
  flex: 0 0 100%;
}


section{
  padding: 1.5rem 1rem;
  @include breakpoint('break-1'){
    padding: 4rem 1rem;
  }
}


.flex{
  display:flex;

  &.flex-desktop{
    display:block;
    @include breakpoint('break-1'){
      display:flex;
    }
  }
}

.wrap{
  flex-wrap:wrap;
}

.sticky{
  position:sticky;
  top:0;
}

.desktop-only{
  display:none;

  @include breakpoint('break-1'){
    display:block;
  }
}
