@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';

.section__tasks{

  ul{
    list-style: none;
    padding:0;
    margin:0;
  }

  li{
    display:flex;
    padding-bottom:8px;

    span{
      padding-right: 1rem;
      svg{ width:auto; height: 10px; vertical-align:middle;}

      @include breakpoint('break-1'){
        padding-right: 0.5rem;
      } 
    }
  }

  .task{
    margin-bottom:2rem;
    
    h3{padding-bottom:2rem;}

    @include breakpoint('break-1'){
      margin-bottom:5rem;
      h3{padding-bottom:0;}
    }

    &:nth-last-of-type(1){
      margin-bottom: 0;
    }


   


  }

  .task__content{ }

}

