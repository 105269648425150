@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';


.start-herospace{
  height: 90vh;
  position:relative;
  //background-color:$plei-green;

  @include breakpoint('break-1'){
    height: 100vh;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  .start-herospace--wrapper{
    height: 90vh;
    padding: 1rem 8vw;
    display:flex;
    justify-content:space-between;
    align-items: flex-end;

    @include breakpoint('break-1'){

      align-items: center;
      height:100vh;

    }
  }

  .letter{
    display: inline-block;
    span{font-size:16vw}
    svg{height: 12vw; margin:unset; display:unset; fill: $plei-orange;}
  }



}


