@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';


.herospace{
  height: 90vh;
  background-color: $plei-black;
  position:relative;
  margin-bottom:2rem;

  @include breakpoint('break-1'){
    height: 100vh;
  }

  h1{
    font-size: 13vw;
    line-height: 1;
    color: $plei-white;
    text-align:center;

    @include breakpoint('break-1'){
      font-size: 12vw;
    }
  }

  .herospace__content{

    top:50%;
    transform: translateY(-50%);
    padding-top:2rem;


    left: 1rem; right: 1rem;
    position: absolute;

    @include breakpoint('break-1'){
      display:flex;
    }
  }

  .arrow{
    height: 6rem;
    position:absolute;
    left:1rem; bottom: 1rem;

    @include breakpoint('break-1'){
      align-self: flex-end;
      height: 10vw;
      margin-top: 0;
      padding-right: 2rem;
      padding-bottom: 1rem;
    }

    svg{
      height:100%; 
      fill: $plei-white;
      }
  }

  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}


