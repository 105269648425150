@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';


.menu__container{
  position:fixed;
  z-index: 99999;
  top:0; right: 0; bottom:0; left: 0;
  background-color: $plei-black;
  animation: fadein 0.5s;
  overflow-y:scroll;
  display:flex;
  justify-content:center;
  align-items: center;

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  .menu__items{
    padding: 1rem 1rem;
    text-align:center;

    .item__menu{
      @include breakpoint('break-1'){padding-left:3rem;}
    }

    h1{
      font-size: 8vh;
      line-height:1.2;

      @include breakpoint('break-1'){
        font-size: 8vw;
      }
    }

    span{font-size: inherit;}

    h1, a, span{ color: $plei-white;}

    a, span{
      cursor:pointer;

      &:hover{background-color:none;}

      @include breakpoint('break-1'){
        padding: 0 3rem;

        &:hover{
          background-color: white;
          border-radius: 100px;
          color: black;
        }
      }
    }
  }

}


