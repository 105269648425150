$breakpoints: (
    'break-1'       :   769px,
);

@mixin breakpoint($breakpoint){
    $value: map-get($breakpoints, $breakpoint);

    @if $value != null {
        @media (min-width: $value){
            @content;
            }
        }

    @else {
        @warn "No value could be retrieved form `#{$breakpoint}`. "
        + "Please make sure it is defined in `breakpoints` map."
        }

    }

