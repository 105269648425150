@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';



.image-grid-item__wrapper{

  padding-bottom:2rem;

  &:last-of-type{padding-bottom:0;}


  @include breakpoint('break-1'){
    padding-bottom: 4rem;

    &:nth-of-type(3n+1){padding-right:1rem; padding-left: 0;}
    &:nth-of-type(3n-1){padding-right:0.5rem; padding-left:0.5rem;}
    &:nth-of-type(3n){padding-right:0; padding-left:1rem;}

    &:nth-last-child(-n+3) {padding-bottom: 0;}
  }

  img{
    display:block;
    width:100%;
    border-radius:25px;
    filter:grayscale(0);
  }

  h4{
    padding-top:1rem;
    text-align:center;
  }

  p{
    padding-top:0.5rem;
    padding-right: 1rem;
    padding-bottom:1rem;
    max-width:80%;
    display:none;
  }

  a:hover{
    .overlay{display:flex;}
    img{filter:grayscale(1)}
  }
  
  .image__wrapper{
    position:relative;
    border-radius:25px;
    overflow:hidden;

    &.secret{
      color: blue;
      background-color: black;
      &:hover{img{filter: blur(20px);}}
    }

    .img__container{
      width: 100%;
      height: 70vh;
      overflow: hidden;
      border-radius: 25px;
      
      @include breakpoint('break-1'){
        height: 46vw;
      }

    }

    .overlay{
      top:0; bottom:0; right:0; left:0;
      position:absolute;
      //background-color:$plei-green;
      z-index:999;
      display:none;
      border-radius:25px;
      align-items:center;
      justify-content: center;
      flex-direction:column;
      
      h2,h4{color: $plei-white; font-size: 6vw;}

      h2{
        font-size:10vh;
        @include breakpoint('break-1'){
          font-size:6vw;
        }

      }

    }
  }

}

.image-grid__more{
  .img__container{
    display:flex;
    justify-content: center;
    align-items:center;
  }
}



