@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';

.section__investments{

  padding-bottom:5rem;

  @include breakpoint('break-1'){
    padding-top:10rem;
    padding-bottom:10rem;
  }

  .investment{
    border-radius: 50px;
    padding: 0 1rem;

    margin:0.2rem;
    display:flex;
    justify-content:center;

    h2{
      font-size: 8vw;
      border-radius:100px;
      padding: 0 4vw;
      opacity:0;

      transition: opacity 0.5s ease-in-out;

      @include breakpoint('break-1'){
        font-size: 8vw;
        &:hover{
          background:black;
          a,span{color: $plei-white;}
        }
      }
    }
    span{font-size: 2vw; vertical-align:middle; padding-left: 0.5rem;}
  }
}
