@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';


.section__hero-headline{

  padding: 0 1rem;
  margin-bottom:5rem;
  margin-top:5rem;
  //height: 80vh;
  justify-content: center;
  align-items:center;
  text-align:center;

  h2{display:inline;}

  &.dark{
    background-color: $plei-black;
    h2{color: $plei-white;}
  }

  &.light{
    background-color: none;
    h2{color: $plei-black;}
    .down-arrow{fill: $plei-black;}
  }


  .down-arrow{
    height: 8vw;
    display: inline-block;
    vertical-align: bottom;
    padding-left: 0.5rem;    
    fill:$plei-white;

    @include breakpoint('break-1'){
      height: 5vw;
      padding-left: 1vw;    
    }

    svg{height: 100%; width: auto;}
  }



}




