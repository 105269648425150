@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';

.cta-large__wrapper{
  margin-bottom:4rem;

  @include breakpoint('break-1'){
    .btn-large{margin-left: 33.3333%}
  }  
}

