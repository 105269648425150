@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';


.section__sub-headline{

  margin-bottom:2rem;

  h2{display:inline;}

  &.dark{
    background-color: $plei-black;
    h2{color: $plei-white;}
  }

  &.light{
    background-color: $plei-white;
    h2{color: $plei-black;}
    .down-arrow{fill: $plei-black;}
  }


  .down-arrow{
    height: 11vw;
    display: inline-block;
    vertical-align: bottom;
    padding-left: 0.5rem;    
    fill:$plei-white;

    @include breakpoint('break-1'){
      height: 5vw;
      padding-left: 1vw;    
    }

    svg{height: 100%; width: auto;}
  }



}




