@import './_breakpoints.scss';
@import './_variables.scss';

@font-face {
  font-family: 'FK-Regular';
  src: local('FK-Regular'), url(../fonts/FKDisplay-Regular.woff) format('woff');
  src: local('FK-Regular'), url(../fonts/FKDisplay-Regular.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'FK-RegularAlt';
  src: local('FK-RegularAlt'), url(../fonts/FKDisplay-RegularAlt.woff) format('woff');
  src: local('FK-RegularAlt'), url(../fonts/FKDisplay-RegularAlt.woff2) format('woff2');
  font-display: swap;
}




h1, h2, h3, h4, p, span, a, li {
    font-family: 'FK-Regular', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin:0;
}

h1{
  font-family: 'FK-RegularAlt', sans-serif;
  font-size: 12vw;
}

h2{
  font-size: 8vw;
  @include breakpoint('break-1'){
    font-size: 5vw;
  }

}

h3{
  font-size: 2.5rem;
  @include breakpoint('break-1'){
    font-size: 3.5rem;
  }
}


h4{
  font-size: 1.8rem;
  @include breakpoint('break-1'){
    font-size: 2rem;
  }

}

span, p{
  font-size: 1.2rem;
  @include breakpoint('break-1'){
    font-size: 1.6rem;
  }
}


a, a:visited{
    color: $plei-black;
    text-decoration: none;
}

a:hover{
    cursor:pointer;
    //background-color: $plei-green;
}

.underline{
  text-decoration:underline;
}

.center-text{
  text-align:center;
}

.super:after{
    font-size: 0.75rem;
    color: red;
    vertical-align: top;

    @include breakpoint('break-1'){
      font-size: 1rem;
    }
}

.super:hover:after{
    color: $plei-black;
}

.ipo:after{
    content: "IPO";
}

.mna:after{
    content: "M&A";
}




