@import './styles/_variables.scss';

*, html, body{
    box-sizing:border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0;
  background-color: #FFFFFF;
}


*::selection {
    background-color: #000000;
    color:#FFFFFF;
}

*::-moz-selection {
    background-color: #000000;
    color:#FFFFFF;
}

.overflow-hidden{
  overflow:hidden;
}

.body--green{
  background-color: $plei-green;
}
