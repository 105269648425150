@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';

.recruitment-page-wrapper{

  .btn{
    display:block;
    height: 4rem;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 50px;

  }

  .btn-green{
    background-color:$plei-green;
    color: $plei-black;

    &:hover{
      background: $plei-black;
      color: $plei-white;
    }
  }

  .btn-large{
    font-size: 1.8rem;
    @include breakpoint('break-1'){
      font-size: 2rem;
    }

  }

}

