@import '../../styles/_breakpoints.scss';
@import '../../styles/_variables.scss';



.header__wrapper{

  .header{
    align-items: center;
    position:absolute;
    background-color: $plei-white;
    z-index: 9999;
    border-radius:50px;

    top:0.5rem;

    a{padding: 0.5rem 0.5rem;}

    &.static{
      background:none;
      left: 50%; transform:translateX(-50%);

      display:none;

      @include breakpoint('break-1'){
        //left:unset; transform:unset; right: 1rem;
        //bottom: 1rem; top:unset;
        top:1rem;
        box-shadow: none;
        //span{display:none;}
      }
      &.white{ h4{color: $plei-white; &:hover{color:black}}}
    }

    &.fixed{
      position:fixed;
      top: -4rem; bottom: unset;
      left: 50%; transform:translateX(-50%);
      animation: slide 0.5s forwards;

      box-shadow:
        1.7px 2px 1.2px rgba(0, 0, 0, 0.021),
        4.2px 4.9px 3.3px rgba(0, 0, 0, 0.028),
        9.1px 10.5px 7.8px rgba(0, 0, 0, 0.037),
        31px 36px 26px rgba(0, 0, 0, 0.07);

    }

    @keyframes slide {
      100% { top: 0.5rem; }
    }


    h4{
      padding: 0.5rem 1rem;
      border-radius:50px;
      cursor:pointer;
      align-items: center;

      &:hover{background-color:$plei-green; color:$plei-black;}

      span{padding-left: 0.5rem; font-size:inherit;}
    }
  

  }

  .smiley{
    position:absolute;
    top:1rem; left:1rem;
    z-index: 9999;
    display:none;

    @include breakpoint('break-1'){display:flex;}
    
    &.white{h4,a{color:white;}}

  }



}
